import { AfterContentInit, AfterViewInit, Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ToastrService } from "ngx-toastr";
import * as CONSTANT from "../../services/constants";
import * as CryptoJS from "crypto-js";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SharedService } from "../shared.service";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { MessagingService } from "src/app/services/messaging.service";
import { AuthApiService } from "src/app/services/social-account.service";
import { Subject } from "rxjs";
declare var AppleID;

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"]
})
export class RegisterComponent implements OnInit, AfterContentInit, AfterViewInit {
  modalRef?: BsModalRef;
  public form: FormGroup;
  showError = false;
  public loading = false;
  userType: number | undefined;
  userTypes;
  relationTypes;
  registerData;
  // social log
  auth2: any;
  isPageLoaded = false;
  @ViewChild("loginRef", { static: true }) loginElement!: ElementRef;
  @ViewChild("gbutton") gbutton: ElementRef = new ElementRef({});
  osType: boolean = false;
  name: any;
  email: any;
  userInfoObserver = new Subject<string>();
  handleCredentialResponse: any;
  constructor(private modalService: BsModalService, private formBuilder: FormBuilder, private authAPI: AuthApiService, public route: ActivatedRoute, private router: Router, private api: AdminService, private toastrService: ToastrService, public zone: NgZone, private sharedService: SharedService, private angularFireMessaging: AngularFireMessaging, private fcm: MessagingService, private afAuth: AngularFireAuth) {
    this.userType = this.sharedService.userType;
    this.form = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(/[\S]/g)])],
      email: ["", Validators.compose([Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX)])],
      userType: [1],
      loginType: [1],
      facebookId: [""],
      gmailId: [""],
      appleId: [""],
      platformType: ["WEB"],
      isAccepted: ["1", Validators.required],
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.gLogin()
    }, 200);
    this.osType = true
    this.route.queryParams.subscribe(params => {
      if (params['name']) {
        this.name = params['name'];
      }
      if (params['email']) {
        this.email = params['email'];
      }
      this.getUserInfo()
    });
    this.fbLibrary();
    this.GetEmailCookieAndReset();
    this.googleSdk();
    let data = localStorage.getItem("deviceToken");
    let userType = localStorage.getItem("userType");
    let veteranRelationName = localStorage.getItem("veteranRelationName");
    this.userTypes = userType;
    this.relationTypes = veteranRelationName;
    if (veteranRelationName == null) {
      this.relationTypes = 0;
    }
    this.form.patchValue({
      userType: userType,
      veteranRelationType: this.relationTypes,
    })

    this.getDeviceToken();
  }

  getUserInfo() {
    this.form.patchValue({
      name: this.name,
      email: this.email,
    })
  }

  gLogin() {

    const clientId =
      '1044983158863-hhjq8ajt9do4vfa1ftimhb6ncdr7mfb8.apps.googleusercontent.com';
    // @ts-ignore

    window.google?.accounts?.id.initialize({
      client_id: clientId,
      callback: this.handleCallback.bind(this),
      // itp_support: true,
      // callback: this.handleCredentialResponse.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });
    // @ts-ignore
    window.google?.accounts?.id.renderButton(
      document.getElementById("google-button"), {
      theme: 'outline',
      size: 'large',
      width: 358,
      shape: 'square',
      click_listener: this.onClickHandler
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const clientId =
        '1044983158863-hhjq8ajt9do4vfa1ftimhb6ncdr7mfb8.apps.googleusercontent.com';
      // @ts-ignore

      window.google?.accounts?.id.initialize({
        client_id: clientId,
        callback: this.handleCallback.bind(this),
        // itp_support: true,
        // callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true,
      });
      // @ts-ignore
      window.google?.accounts?.id.renderButton(
        document.getElementById("google-button"), {
        theme: 'outline',
        size: 'large',
        width: 358,
        shape: 'square',
        click_listener: this.onClickHandler
      });
      // window.google?.accounts?.id.renderButton(this.gbutton.nativeElement, {
      //   type: 'standard',
      //   theme: 'outline',
      //   size: 'large',
      //   width: '350',
      //   shape: 'rectange',
      // });
      // @ts-ignore
      google.accounts?.id.prompt();

    }, 200);
  }
  onClickHandler() {

  }
  // @ts-ignore
  handleCallback(response: google.accounts.id.CredentialResponse) {
    let responsePayload = this.decodeJwtResponse(response.credential);
    const SocialProfile: any = {
      loginType: 3,
      email: responsePayload.email,
      gmailId: responsePayload.sub,
      platformType: "WEB",
      userType: this.form.value.userType,
      veteranRelationType: this.relationTypes,
    };

    this.PresenterSignUp(SocialProfile);
  }
  ngAfterContentInit() {
    this.isPageLoaded = true;
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("deviceToken");
    if ((deviceToken) && (deviceToken != "")) {
      return deviceToken;
    }
    else {
      this.fcm.requestPermission();
      deviceToken = localStorage.getItem("fireBaseToken")
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }
  // Get Email from Cookie and Reset
  GetEmailCookieAndReset() {
    const emailFromCookie: any = this.GetEmailFromCookie();
    if (emailFromCookie != "") {
      this.form.controls.email.reset(emailFromCookie);
    }
  }

  // Get Email from Cookie
  GetEmailFromCookie() {
    const name: any = "appemail=";
    const decodedCookie: any = decodeURIComponent(document.cookie);
    const SplittedCookieInfo: any = decodedCookie.split(";");
    for (let i = 0; i < SplittedCookieInfo.length; i++) {
      let CookieInfo: any = SplittedCookieInfo[i];
      while (CookieInfo.charAt(0) === " ") {
        CookieInfo = CookieInfo.substring(1);
      }
      if (CookieInfo.indexOf(name) === 0) {
        return CookieInfo.substring(name.length, CookieInfo.length);
      }
    }
    return "";
  }
  // Google Login
  googleSdk() {
    (window as any).handleCredentialResponse = (response: any) => {
      let responsePayload = this.decodeJwtResponse(response.credential);
      const SocialProfile: any = {
        loginType: 3,
        email: responsePayload.email,
        gmailId: responsePayload.sub,
        platformType: "WEB",
        userType: this.form.value.userType,
        veteranRelationType: this.relationTypes,
      };
      this.PresenterSignUp(SocialProfile);
    }
  }
  // decorder google login
  decodeJwtResponse(token: string) {
    let base64Url = token.split(".")[1]
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));
    return JSON.parse(jsonPayload)
  }
  googleLogin() {
    this.loginElement.nativeElement
  }
  // Facebook Login 
  fbLibrary() {
    (window as any).fbAsyncInit = function () {
      window["FB"].init({
        appId: "529701082679293",
        cookie: true,
        xfbml: true,
        version: "v16.0"
      });
      window["FB"].AppEvents.logPageView();
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode?.insertBefore(js, fjs);
    }(document, "script", "facebook-jssdk"));

  }

  // Facebook Login API Call
  loginFacebook() {
    window["FB"].login((response) => {
      if (response.authResponse) {
        window["FB"].api("/me", {
          fields: "last_name, first_name, email"
        }, (userInfo) => {
          this.zone.run(() => {
            let FBInfo: any = {
              loginType: 2,
              name: userInfo.first_name + " " + userInfo.last_name,
              email: userInfo.email,
              facebookId: userInfo.id,
              userType: this.form.value.userType,
              platformType: "WEB",
              veteranRelationName: this.form.value.veteranRelationName,
            }
            this.PresenterSignUp(FBInfo);
          });
        });

      } 
    }, { scope: "email" });
  }
  // Google Login API Call
  PresenterSignUp(socialProfile: any) {
    this.authAPI.PresenterSignUp(socialProfile).subscribe((response: any) => {
      this.authAPI.SaveAuthData(response.data, response.data.accessToken);
      this.Savemailincookie(response.data.email);
      this.onLoginSucess(response, this.getDeviceToken());
    }, (error) => {
    });
  }
  Savemailincookie(email) {
    this.SaveEmailInCookie(email);
  }
  // Save Email in Cookie
  SaveEmailInCookie(Email: any) {
    document.cookie = "appemail=" + Email;
  }
  onLoginSucess(res, deviceToken) {
    localStorage.setItem("accessToken", res.data.accessToken)
    localStorage.setItem("login_type", "user");
    window.localStorage.setItem("userDetail", JSON.stringify(res.data));
    localStorage.setItem("s3Folders", JSON.stringify(res.data.s3Folders));
    this.toastrService.success("Login Successfully!");
    localStorage.setItem("deviceToken", deviceToken)
    if (res.data.userResumeStatus.isSteped == 0) {
      this.router.navigate(["/upload-document"]);
    } else if (res.data.userResumeStatus.isSteped == 12) {
      this.router.navigate(["/dashboard-job"])
    }
    return false;
  }
  // sign in with apple id
  // sign in with apple id 
  public async signInWithApple() {
    try {
      AppleID.auth.init({
        clientId: 'com.web.HonorVet',
        scope: 'name email',
        redirectURI:
          'https://honorvets.com//login',
        state: 'init',
        nonce: 'test',
        usePopup: true, //or false defaults to false
        responseType: '', //or false defaults to false
      });
      const data = await AppleID.auth.signIn();
      let resData = this.parseJwt(data.authorization.id_token);
      this.zone.run(() => {
        let AppleInfo: any = {
          loginType: 4,
          // name: userInfo.first_name + " " + userInfo.last_name,
          email: resData.email,
          appleId: resData.aud,
          platformType: "WEB",
          userType: this.form.value.userType,
          veteranRelationType: this.relationTypes,
        }
        this.PresenterSignUp(AppleInfo);
      });
    } catch (error) {
      console.log(error);

      // this.toastrService.error("Error", error)
      //handle error.
    }
  }
  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
  // register
  signUp() {
    if (this.form.valid) {
      if (this.form.value.isAccepted == false) {
        this.showError = true
        setTimeout(() => {
          this.showError = false
        }, 3000);
        return
      }
      this.loading = true;
      delete this.form.value.isAccepted;
      const data = {
        "name": this.form.value.name.trim(),
        "email": this.form.value.email,
        "loginType": this.form.value.loginType,
        "userType": this.form.value.userType,
        "veteranRelationName": this.form.value.veteranRelationName,
        "platformType": "WEB",
        "deviceToken": this.getDeviceToken(),
      };
      this.api.postData(apiUrl._userRegister, data)
        .subscribe(async (res) => {
          this.registerData = res
          this.loading = false
          if (res.data.accessToken) {
            const accessToken = res.data.accessToken;
            this.sharedService.accessToken = accessToken;
          }
          localStorage.setItem("accessToken", res.data.accessToken)
          localStorage.setItem("userId", res.data.id)
          window.localStorage.setItem("userData", JSON.stringify(res.data));
          window.localStorage.setItem("userDetail", JSON.stringify(res.data));
          localStorage.setItem("s3Folders", JSON.stringify(res.data.s3Folders));
          let queryparams = {}
          queryparams["name"] = res.data?.name;
          queryparams["email"] = res.data?.email;
          this.router.navigate(["/verify-otp"], { queryParams: queryparams })
          // this.router.navigate(["/verify-otp"]);
        },
          err => {
            this.toastrService.error("Error", err.error.message)
            this.loading = false
          })
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 3000);
    }
  }
  // 
}
