
export const ImageUrls = {
  // BaseUrl: "https://honorvet-dev.s3.us-east-1.amazonaws.com/",
  BaseUrl: "https://media.honorvets.com/",
  directories: {
    "users": "users/",
    "admin": "admin/",
    "recruiter": "recruiter/",
    "comman": "comman/",
    "chatDirectory": "chatdocuments/"
  },
}
