import { AfterContentInit, AfterViewInit, Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ToastrService } from "ngx-toastr";
import * as CONSTANT from "../../services/constants";
import * as CryptoJS from "crypto-js";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SharedService } from "../shared.service";

import { MessagingService } from "../../services/messaging.service"
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AuthApiService } from "src/app/services/social-account.service";
import { UserDetailsService } from "src/app/services/user/user-details.service";
declare var AppleID;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})

export class LoginComponent implements OnInit, AfterContentInit, AfterViewInit {
  modalRef?: BsModalRef;
  public form: FormGroup;
  public registerForm: FormGroup;
  showError = false;
  public loading = false;
  rememberMe = false;
  encryptedPassword: any;
  showPwd: boolean = false;
  tokenFromUI = "0123456789123456";
  auth2: any;
  isPageLoaded = false;
  @ViewChild("loginRef", { static: true }) loginElement!: ElementRef;
  accessToken: any;
  @ViewChild('gbutton') gbutton: ElementRef = new ElementRef({});
  osType: boolean = false;
  jwtHelper: any;
  handleCredentialResponse: any;
  constructor(private modalService: BsModalService, private authAPI: AuthApiService,
    private formBuilder: FormBuilder, public route: ActivatedRoute, private router: Router,
    private api: AdminService, private toastrService: ToastrService, public zone: NgZone,
    private sharedService: SharedService, private angularFireMessaging: AngularFireMessaging,
    private fcm: MessagingService, private afAuth: AngularFireAuth, private userDetailsService: UserDetailsService) {

    this.form = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX)])],
      password: ["", Validators.compose([Validators.required])],
    });
    this.registerForm = this.formBuilder.group({
      userType: ["", Validators.compose([Validators.required])],
    });
    this.accessToken = localStorage.getItem("accessToken");
  }

  ngOnInit(): void {
    this.gLogin()
    this.osType=true
    // if(navigator.platform.indexOf('Win') > -1){
    //   this.osType=false
    // } else{
    //   this.osType=true
    // }
    this.fbLibrary();
    this.googleSdk();
    this.GetEmailCookieAndReset();
    let data = localStorage.getItem("accessToken");
    if (data) {
      this.router.navigate(["/dashboard-job"]);
    }
    window.localStorage.setItem("isPasswordSet", "0")
    let rememberMe = localStorage.getItem("rememberMe");
    if (rememberMe == "1") {
      this.form.patchValue({
        email: localStorage.getItem("email"),
      });
      this.rememberMe = true;
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      this.rememberMe = false;
    }
    this.getDeviceToken();

  }
  gLogin(){
    setTimeout(() => {
      const clientId =
        '1044983158863-hhjq8ajt9do4vfa1ftimhb6ncdr7mfb8.apps.googleusercontent.com';
      // @ts-ignore
      
      window.google?.accounts?.id.initialize({
        client_id: clientId,
        callback: this.handleCallback.bind(this),
        // itp_support: true,
        // callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true,
      });
      // @ts-ignore
      window.google?.accounts?.id.renderButton(
        document.getElementById("google-button"), {
          theme: 'outline',
          size: 'large', 
          width: 358,
          shape: 'square',
          click_listener: this.onClickHandler
        });
      // window.google?.accounts?.id.renderButton(this.gbutton.nativeElement, {
      //   type: 'standard',
      //   theme: 'outline',
      //   size: 'large',
      //   width: '350',
      //   shape: 'rectange',
      // });
      // @ts-ignore
      google.accounts?.id.prompt();
    }, 200);
  }
  ngAfterViewInit() {
    setTimeout(() => {
    const clientId =
      '1044983158863-hhjq8ajt9do4vfa1ftimhb6ncdr7mfb8.apps.googleusercontent.com';
    // @ts-ignore
    
    window.google?.accounts?.id.initialize({
      client_id: clientId,
      callback: this.handleCallback.bind(this),
      // itp_support: true,
      // callback: this.handleCredentialResponse.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });
    // @ts-ignore
    window.google?.accounts?.id.renderButton(
      document.getElementById("google-button"), {
        theme: 'outline',
        size: 'large', 
        width: 358,
        shape: 'square',
        click_listener: this.onClickHandler
      });
    // window.google?.accounts?.id.renderButton(this.gbutton.nativeElement, {
    //   type: 'standard',
    //   theme: 'outline',
    //   size: 'large',
    //   width: '350',
    //   shape: 'rectange',
    // });
    // @ts-ignore
    google.accounts?.id.prompt();
  }, 200);
  }
 onClickHandler(){
    console.log("Sign in with Google button clicked...")
  }
  // @ts-ignore
  handleCallback(response: google.accounts.id.CredentialResponse) {
    let responsePayload = this.decodeJwtResponse(response.credential);
    const SocialProfile: any = {
      loginType: 3,
      email: responsePayload.email,
      gmailId: responsePayload.sub,
      platformType: "WEB"
    };

    this.VerifyGoogleLogin(SocialProfile);
  }
  ngAfterContentInit() {
    this.isPageLoaded = true;
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("deviceToken");
    if ((deviceToken) && (deviceToken != "")) {
      return deviceToken;
    }
    else {
      this.fcm.requestPermission();
      deviceToken = localStorage.getItem("fireBaseToken")
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }

  // Get Email from Cookie and Reset
  GetEmailCookieAndReset() {
    const emailFromCookie: any = this.GetEmailFromCookie();
    if (emailFromCookie != "") {
      this.form.controls.email.reset(emailFromCookie);
    }
  }
  // Get Email from Cookie
  GetEmailFromCookie() {
    const name: any = "appemail=";
    const decodedCookie: any = decodeURIComponent(document.cookie);
    const SplittedCookieInfo: any = decodedCookie.split(";");

    for (let i = 0; i < SplittedCookieInfo.length; i++) {
      let CookieInfo: any = SplittedCookieInfo[i];
      while (CookieInfo.charAt(0) === " ") {
        CookieInfo = CookieInfo.substring(1);
      }
      if (CookieInfo.indexOf(name) === 0) {
        return CookieInfo.substring(name.length, CookieInfo.length);
      }
    }
    return "";
  }

  // Google Login
  googleSdk() {
    (window as any).handleCredentialResponse = (response: any) => {
      let responsePayload = this.decodeJwtResponse(response.credential);
      const SocialProfile: any = {
        loginType: 3,
        email: responsePayload.email,
        gmailId: responsePayload.sub,
        platformType: "WEB"
      };
      this.VerifyGoogleLogin(SocialProfile);
    }
  }
  // decorder google login
  decodeJwtResponse(token: string) {
    let base64Url = token.split(".")[1]
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));
    return JSON.parse(jsonPayload)
  }
  googleLogin() {
    this.loginElement.nativeElement
  }
  // Facebook Login  
  fbLibrary() {
    (window as any).fbAsyncInit = function () {
      window["FB"].init({
        appId: "529701082679293",
        cookie: true,
        xfbml: true,
        version: "v16.0"
      });
      window["FB"].AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode?.insertBefore(js, fjs);
    }(document, "script", "facebook-jssdk"));

  }

  // Facebook Login API Call
  loginFacebook() {
    window["FB"].login((response) => {
      if (response.authResponse) {
        window["FB"].api("/me", {
          fields: "last_name, first_name, email"
        }, (userInfo) => {
          this.zone.run(() => {
            let FBInfo: any = {
              loginType: 2,
              // name: userInfo.first_name + " " + userInfo.last_name,
              email: userInfo.email,
              facebookId: userInfo.id,
              platformType: "WEB"
            }
            this.VerifyGoogleLogin(FBInfo);
          });
        });
      } else {
      }
    }, { scope: "public_profile,email" });
  }

  // Google Login API Call
  VerifyGoogleLogin(socialProfile: any) {
    this.authAPI.VerifyGoogleLogin(socialProfile).subscribe((response: any) => {
      this.authAPI.SaveAuthData(response.data, response.data.accessToken);
      this.Savemailincookie(response.data.email);
      this.onLoginSucess(response, this.getDeviceToken());
    }, (error) => {
    });
  }
  Savemailincookie(email) {
    this.SaveEmailInCookie(email);
    // if (this.form.controls.rememberMe.value) {
    //   this.SaveEmailInCookie(this.form.controls.email.value);
    // }
  }
  // Save Email in Cookie
  SaveEmailInCookie(Email: any) {
    document.cookie = "appemail=" + Email;
  }
  // sign in with apple id 
  public async signInWithApple() {
    try {  
      AppleID.auth.init({
        clientId: 'com.web.HonorVet',
        scope: 'name email',
        redirectURI:
          'https://honorvets.com/login',
        state: 'init',
        nonce: 'test',
        usePopup: true, //or false defaults to false
        responseType: '', //or false defaults to false
      });
      const data = await AppleID.auth.signIn();
      let resData=this.parseJwt(data.authorization.id_token);  
      this.zone.run(() => {
        let AppleInfo: any = {
          loginType: 4,
          // name: userInfo.first_name + " " + userInfo.last_name,
          email: resData.email,
          appleId: resData.aud,
          platformType: "WEB"
        }
        this.VerifyGoogleLogin(AppleInfo);
      });
    } catch (error) {
      console.log(error);
    }
  }
  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
  // Encryption Functionality
  encryptUsingAES256(value) {
    return new Promise((resolve, reject) => {
      let ciphertext = CryptoJS.AES.encrypt(value, this.tokenFromUI);
      resolve(ciphertext);
    });
  }

  // Decryption Functionality
  decryptUsingAES256(value) {
    let bytes = CryptoJS.AES.decrypt(value.toString(), this.tokenFromUI);
    let plaintext = bytes.toString(CryptoJS.enc.Utf8);
    this.form.patchValue({
      password: plaintext,
    });
  }

  // Change Remember Me Functionality
  changeRememberMe(event) {
    this.rememberMe = event.target.checked;
  }

  /******* Login Functionality ******/
  login() {
    if (this.form.value.isAccepted == false) {
      this.showError = true
      setTimeout(() => {
        this.showError = false
      }, 3000);
      return
    }
    if (this.form.valid) {
      this.loading = true;
      let deviceToken = this.getDeviceToken()
      const data = {
        "email": this.form.value.email,
        "password": this.form.value.password,
        "platformType": "WEB",
        "deviceToken": deviceToken,

      };
      this.api.postData(apiUrl._userLogin, data)
        .subscribe(async (res) => {
          this.loading = false
          this.onLoginSucess(res, deviceToken);
        },
          err => {
            this.toastrService.error("Error", err.error.message)
            this.loading = false
          })
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 3000);
    }
  }
  onLoginSucess(res, deviceToken) {
    localStorage.setItem("accessToken", res.data.accessToken)
    localStorage.setItem("userId", res.data.id) 
    this.toastrService.success("Logged in successfully.");
    localStorage.setItem("deviceToken", deviceToken)
    this.api.getDatas(apiUrl._getUserResumeStatus)
      .subscribe(async (res) => {
        this.userDetailsService.getUserData();
        if(res.data.isSteped == 12){
          localStorage.setItem("login_type", "userProfileCompleted");
        } else{
          localStorage.setItem("login_type", "userProfileInCompleted");
        }    
        if (res.data) {
          this.checkRouteNevigation(
            JSON.parse(
              JSON.stringify(res.data)
            )
          );
        } else {
          this.checkRouteNevigation(
            JSON.parse(
              JSON.stringify(res.data)
            )
          );
        }
      })
    return false;
  }
  checkRouteNevigation(permissions) {
    if (permissions.isSteped === 0) {
      this.router.navigate(["upload-document"]);
    }
    else if (permissions.isSteped === 1) {
      this.router.navigate(["complete-profile"]);
    }
    else if (permissions.isSteped === 2) {
      this.router.navigate(["resume-build"]);
    }
    else if (permissions.isSteped === 3) {
      this.router.navigate(["skills"]);
    }
    else if (permissions.isSteped === 4) {
      this.router.navigate(["education"]);
    }
    else if (permissions.isSteped === 5) {
      this.router.navigate(["experience"]);
    }
    else if (permissions.isSteped === 6) {
      this.router.navigate(["volunteer"]);
    }
    else if (permissions.isSteped === 7) {
      this.router.navigate(["projects"]);
    }
    else if (permissions.isSteped === 8) {
      this.router.navigate(["awards"]);
    }
    else if (permissions.isSteped === 9) {
      this.router.navigate(["licenese"]);
    }
    else if (permissions.isSteped === 10) {
      this.router.navigate(["language"]);
    }
    else if (permissions.isSteped === 11) {
      this.router.navigate(["jobs"]);
    }
    else if (permissions.isSteped === 13) {
      this.router.navigate(["overview"]);
    }
    else if (permissions.isSteped === 12) {
      this.router.navigate(["/dashboard-job"])
    }
  }
  open(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray registerModal" })
    );
  }
  // for register screen type
  signUp() {
    if (this.registerForm.valid) {
      const userType = this.registerForm.get("userType")?.value;
      this.sharedService.userType = userType;
      localStorage.setItem("userType", userType)
      if (userType == 0) {
        this.router.navigate(["register"]);
      } else {
        this.router.navigate(["choose-yourself"]);
      }
      this.modalRef?.hide();
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 3000);
    }
  }
}
