import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { DataSharingService } from "../../services/data.share.service";
import swal from "sweetalert2";
import { ImageUrls } from "../../services/s3buket";
import { Header2Service } from "./header2.service"
import { MessagingService } from "../../services/messaging.service";
import { UserDetailsService } from "src/app/services/user/user-details.service";
import { HttpParams } from "@angular/common/http";

@Component({
  selector: "app-header2",
  templateUrl: "./header2.component.html",
  styleUrls: ["./header2.component.css"]
})
export class Header2Component implements OnInit {
  toggleSidebar = false;
  userData: any;
  globalUrl = ImageUrls.BaseUrl + ImageUrls.directories.recruiter;
  showHead: boolean = false;
  showLogout: boolean = false;
  profileImage;
  deviceToken: any;
  userId: any;
  limit = 4;
  skip = 1;
  message;
  unreadCount: any;
  public userDatas: any;
  public userImage: any;
  public allData: any = [];
  constructor(private router: Router, public route: ActivatedRoute, private api: AdminService, private dataSharingService: DataSharingService, private headerService: Header2Service, private fcm: MessagingService, private userDetailsService: UserDetailsService) {
    this.userDetailsService.getRecruiterUserData();
    this.userDetailsService.userDetailsObserver.subscribe(userData => {
      this.userDatas = userData;

    });
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (!this.router.url.endsWith("/jobs/add")) {
          localStorage.removeItem("currentPostId");
        }
      }
    })
    this.getNotification();
  }

  ngOnInit(): void {
    this.deviceToken = localStorage.getItem("deviceToken");
    this.userId = localStorage.getItem("userId");
    this.fcm.requestPermission()
    // this.fcm.receiveMessage()
    this.fcm.currentMessage.subscribe((msg) => {
      this.message = msg
      this.getNotification()
    })
  }

  getDeviceToken() {
    let deviceToken = localStorage.getItem("deviceToken");
    if ((deviceToken) && (deviceToken != "")) {
      return deviceToken;
    }
    else {
      this.fcm.requestPermission();
      deviceToken = localStorage.getItem("fireBaseToken");
      if (deviceToken)
        return deviceToken.toString();
      else
        return "NOTOKEN";
    }
  }

  onClickBell() {
    this.message = null;
    this.getNotification()
  }
  getNotification() {
    this.api.stopLoader();
    let params = new HttpParams();
    params = params.append("skip", this.skip.toString());
    params = params.append("limit", this.limit.toString());
    this.api.getData(apiUrl._getNotificationListing, params).subscribe(async (res) => {
      this.unreadCount = res.data.unReadCount;
      this.allData = res.data.list;
      // this.allData= res.data.list.sort((n1, n2) => {
      //   if (n1.isRead > n2.isRead) {
      //     return 1;
      //   }
      //   if (n1.isRead < n2.isRead) {
      //     return -1;
      //   }
      //   return 0;
      // })
    },
      err => {
        console.log(err.error.message, "errr")
      })
  }
  toggleNavbar(event: any) {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("sidebar-mini");
    if ((this.toggleSidebar = !this.toggleSidebar)) {
    } else {
      body.classList.remove("sidebar-mini");
    }
  }
  logout() {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      swal.fire({
        html: "<p class='gilroy-bold'>Are you sure you want to Logout?</p>",
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        customClass: {
          popup: 'post-popup logout-pop',
          // confirmButton: 'confirm-button-class',
          // cancelButton: 'cancel-button-class'
        },
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "Cancel",
        confirmButtonColor: "#FE831D",
        cancelButtonColor: "#5A50CC",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: true,
        width: 500,


      }).then((result) => {
        if (result.value) {
          const data = {
            "deviceToken": this.deviceToken
          }
          this.api.putData(apiUrl._LogoutEmp, data).subscribe(
            res => {
              this.clearLocalStorage();
            });
        }
        else if (result.isDismissed == true) {
        }
      })
    } else {
      this.clearLocalStorage();
    }

  }
  clearLocalStorage() {
    window.localStorage.clear();
    localStorage.clear()
    this.fcm.currentMessage.unsubscribe()
    localStorage.removeItem("accessToken");
    localStorage.removeItem("deviceToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("isPasswordSet");
    localStorage.removeItem("login_type");
    localStorage.removeItem("userType");
    localStorage.removeItem("userData");
    localStorage.removeItem("s3Folders");
    this.router.navigate(["/home"]);

  }
  redirectTo(data) {
    const updateStatus = {
      id: data.id,
    }
    this.api.putData(apiUrl._getNotificationRead, updateStatus)
      .subscribe(async (res) => {
        if (data.notificationType == "11") {
          this.router.navigate([`/jobs/applicant/${data.jobId}/appdetail/`], { queryParams: { userId: `${data.userId}` } });
        } else if (data.notificationType == "12") {
          this.router.navigateByUrl(`/jobs/detail/${data.jobId}`);
        } else {
          this.router.navigate(["/account-settings/inventory-history"]);
        }
        this.getNotification()
      })
  }
}
